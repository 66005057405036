module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-141399299-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Iliuta Stoica","short_name":"IliStoica","description":"I’m a passionate frontend developer, with 8+ years professional experience in the following technologies: HTML, CSS, LESS, SASS, Parallax, Javascript, jQuery, Bootstrap, PHP, Wordpress, PWA, AMP. For version control SVN, GIT, Gitlab CI.","start_url":"/","background_color":"#2772d0","theme_color":"#2772d0","display":"standalone","icon":"src/images/meta/startup-image-512x512.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"bc8535c9e020ed434c8c7905b4b3a852"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
